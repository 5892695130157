<template>
    <div>

        <new-form-view
            :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
            :add-new-user="addNewUser"
            :user-selected="userSelected"
            @refetch-data="refetchData"
        />

        <div class="m-2">

            <!-- Table Top -->
            <b-row>
                <!-- Search -->
                <b-col
                    cols="12"
                    md="12"
                >
                    <div class="d-flex align-items-center justify-content-end w-100" style="width: 100%;">
                        <b-button-group>
                            <b-button
                                variant="primary"
                                @click="addNew"
                            >
                                <span class="text-nowrap">Agregar noticia</span>
                            </b-button>
                        </b-button-group>

                    </div>
                </b-col>
            </b-row>

        </div>

        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0"
        >
            <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="fetchUsers"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                empty-text="No matching records found"
            >


                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-button-group>
                        <b-button variant="primary" @click="editUser(data.item)">
                            <feather-icon
                                icon="EditIcon"
                                size="16"
                                class="align-middle"
                            />
                        </b-button>
                        <b-button variant="danger" @click="deleteUser(data.item)">
                            <feather-icon
                                icon="Trash2Icon"
                                size="16"
                                class="align-middle"
                            />
                        </b-button>
                    </b-button-group>
                </template>

            </b-table>
        </b-card>
    </div>
</template>

<script>
import { BCard, BTable, BButton, BButtonGroup, BRow, BCol } from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import useNewsList from '@/views/news/useNewsList'
import newStoreModule from '@/views/news/newStoreModule'
import NewFormView from '@/views/news/NewFormView'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
    name: 'ProductsView',
    components: {
        NewFormView,
        BRow,
        BCard,
        BTable,
        BButton,
        BButtonGroup,
        BCol,
    },
    methods: {
        numberFormat(number) {
            number = parseFloat(number)
            let val = (number / 1).toFixed(2)//.replace('.', ',')
            return val.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        },
        addNew() {
            this.userSelected = {}
            this.addNewUser = true
            this.isAddNewUserSidebarActive = true
        },
        editUser(user) {
            this.userSelected = user
            this.addNewUser = false
            this.isAddNewUserSidebarActive = true
        },
        deleteUser(user) {

            this.$swal({
                title: '¿Seguro que desea eliminar la noticia?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            })
                .then(result => {
                    if (result.value) {
                        store
                            .dispatch('app-news/deleteNew', user)
                            .then(() => {
                                this.refetchData()
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Se ha borrado correctamente',
                                        icon: 'AlertTriangleIcon',
                                        variant: 'success',
                                    },
                                })
                            })
                            .catch(() => {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Error al borrar',
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger',
                                    },
                                })
                            })
                    }
                })
        }
    },
    setup() {
        const USER_APP_STORE_MODULE_NAME = 'app-news'

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, newStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const isAddNewUserSidebarActive = ref(false)

        const {
            fetchUsers,
            tableColumns,
            refUserListTable,
            refetchData,
        } = useNewsList()

        const addNewUser = ref(true)
        const userSelected = ref({})

        return {

            // Sidebar
            isAddNewUserSidebarActive,

            fetchUsers,
            tableColumns,
            refUserListTable,
            refetchData,
            addNewUser,
            userSelected
        }
    }
}
</script>

<style scoped>

</style>
