import firebase from 'firebase/compat/app'
//import { deleteUser  } from "firebase/auth"

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchNews() {
            return new Promise(resolve => {
                firebase
                  .firestore()
                  .collection('news')
                  .get()
                  .then(snapshot => {
                      let users = []
                      snapshot.docs.forEach(u => {
                          const user = u.data()
                          user.id = u.id

                          users.push(user)
                      })

                      resolve(users)
                  })
            })
        },
        uploadImage(ctx, data) {
            return new Promise(resolve => {
                const storageRef = firebase.storage()
                  .ref('/news/' + data.name)
                  .put(data.image)

                storageRef.then(() => {
                    storageRef.snapshot.ref.getDownloadURL()
                      .then(url => {
                          resolve(url)
                      })
                })
            })
        },
        deleteImage(ctx, url) {
            return new Promise(resolve => {
                const storageRef = firebase.storage()
                  .refFromURL(url)
                  .delete()

                storageRef.then(() => {
                    resolve(true)
                })
            })
        },
        addNew(ctx, data) {
            return new Promise(resolve => {
                firebase.firestore()
                  .collection('news')
                  .add(data)
                  .then(() => {
                      resolve(true)
                  })
            })
        },
        editNew(ctx, data) {
            return new Promise(resolve => {
                firebase.firestore()
                  .collection('news')
                  .doc(data.id)
                  .set(data)
                  .then(() => {
                      resolve(true)
                  })
            })
        },
        deleteNew(ctx, data) {
            // console.log(data.id)
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('news')
                  .doc(data.id)
                  .delete()
                  .then(() => {
                      resolve(true)
                  }).catch(error => {
                      reject(error)
                    // console.log("Error removing document: ", error)
                })

            })
        }
    }
}
