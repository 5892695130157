<template>
    <b-sidebar
        id="add-new-user-sidebar"
        :visible="isAddNewUserSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="on_hidden"
        @change="(val) =>{$emit('update:is-add-new-user-sidebar-active', val)}"
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    Formulario de noticias
                </h5>

                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />

            </div>

            <!-- BODY -->
            <validation-observer
                #default="{ handleSubmit, invalid }"
                ref="refFormObserver"
            >
                <!-- Form -->
                <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >
                    <input type="hidden" name="id" value="">

                    <!-- Username -->
                    <validation-provider
                        #default="validationContext"
                        name="title"
                        rules="required"
                    >
                        <b-form-group
                            label="Título de noticia"
                            label-for="title"
                        >
                            <b-form-input
                                id="title"
                                v-model="userData.title"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Username -->
                    <validation-provider
                        #default="validationContext"
                        name="text"
                        rules="required"
                    >
                        <b-form-group
                            label="Texto"
                            label-for="descrition"
                        >
                            <b-form-textarea
                                id="description"
                                v-model="userData.text"
                                :state="getValidationState(validationContext)"
                                trim
                                style="height: 350px;"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                        #default="validationContext"
                        name="multimedia"
                        v-if="userData.image.length===0"
                        rules="required"

                    >
                        <b-form-group
                            label="Imagen"
                            label-for="multimedia"
                        >
                            <b-form-file
                                id="multimedia"
                                accept="image/*"
                                @change="onImageSelected"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <div v-if="userData.image.length>0">
                        <b-avatar :src="userData.image" size="10rem" class="mr-1"></b-avatar>
                        <b-button type="button" variant="danger" @click="onDeleteImage">
                            <feather-icon
                                icon="Trash2Icon"
                                size="16"
                                class="align-middle"
                            />
                        </b-button>
                    </div>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                            :disabled="invalid || loading"
                        >
                            Guardar
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                        >
                            Cancelar
                        </b-button>
                    </div>

                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BFormFile, BAvatar
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BFormFile,
        BAvatar,
        BFormTextarea,

        // Form Validation
        ValidationProvider,
        ValidationObserver,

    },
    directives: {
        Ripple,
    },
    model: {
        prop: 'isAddNewUserSidebarActive',
        event: 'update:is-add-new-user-sidebar-active',
    },
    props: {
        isAddNewUserSidebarActive: {
            type: Boolean,
            required: true,
        },
        userSelected: {
            type: Object,
            required: false
        },
        addNewUser: {
            type: Boolean,
            required: true
        }
    },
    watch: {
        addNewUser: function (newValue) {
            if (newValue) {
                this.userData = {
                    title: '',
                    text: '',
                    image: ''
                }
            } else {
                this.userData = {
                    id: this.userSelected.id,
                    title: this.userSelected.title,
                    text: this.userSelected.text,
                    image: this.userSelected.image,
                }
            }
        },
        userSelected: function (newValue, oldValue) {
            if (newValue.id && oldValue.id && newValue.id.toString() !== oldValue.id.toString()) {
                this.userData = {
                    id: this.userSelected.id,
                    title: this.userSelected.title,
                    text: this.userSelected.text,
                    image: this.userSelected.image,
                }
            } else if (!newValue.id) {
                this.userData = {
                    title: '',
                    text: '',
                    image: ''
                }
            }
        }
    },
    methods: {
        on_hidden() {
            if (this.addNewUser) {
                this.resetForm()
            }
        },
        onSubmit() {

            store.dispatch('app-news/' + (this.addNewUser ? 'addNew' : 'editNew'), this.userData)
                .then(() => {
                    this.$emit('refetch-data')
                    this.$emit('update:is-add-new-user-sidebar-active', false)
                })

        },
        onImageSelected(e) {
            if (e.target.files.length > 0) {
                this.loading = true
                const image = e.target.files[0]
                this.multimediaObject = {
                    name: image.name,
                    image
                }

                store.dispatch('app-news/uploadImage', this.multimediaObject)
                    .then(url => {
                        this.userData.image = url
                        this.loading = false
                    })
            }
        },
        onDeleteImage() {
            this.$swal({
                title: '¿Seguro que desea eliminar la imagen?',
                text: 'Se borrará el archivo permanentemente',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            })
                .then(result => {
                    if (result.value) {
                        store.dispatch('app-products/deleteImage', this.userData.image)
                            .then(() => {
                                this.userData.image = ''
                                this.loading = false
                            })
                    }
                })
        }
    },
    data() {
        return {
            required,
            alphaNum,
            email,
        }
    },
    setup() {

        const blankUserData = {
            title: '',
            text: '',
            image: ''
        }

        const loading = ref(true)

        const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
        const resetuserData = () => {
            userData.value = JSON.parse(JSON.stringify(blankUserData))
        }

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation(resetuserData)

        const multimediaObject = ref(null)

        return {
            userData,
            refFormObserver,
            getValidationState,
            resetForm,
            multimediaObject,
            loading
        }
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
